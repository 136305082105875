<template>
    <ToastNotification :message="message" :isVisible="isVisible" :bgColor="bgc" />

    <div class="refferal">
        <section class="first-container">
            <div class="left-side">
                <div class="img_gift">
                    <img loading="lazy" decoding="async" src="../assets/image_gift.svg" width="250" height="150" alt="" />
                    <p>
                        {{ $t("Invite a friend") }}, <span class="colorfull"> {{ $t("you both get") }} $100</span><br />
                        <span> {{ $t("Invite your friends") }}. </span>
                    </p>
                </div>
                <div class="invitation_btn">
                    <input type="email" name="invite_email" v-model="invitedEmail" id="invite_email" :placeholder="$t('Email Adress')" />
                    <button v-if="!isLoading" type="submit" @click="inviteEmail">{{ $t("Send Invitation") }}</button>
                    <button v-else>
                        <LoaderComponent />
                    </button>
                </div>
            </div>
            <div class="right-side">
                <span>{{ $t("More ways to invite your friends") }} : </span>
                <div class="other_invitation">
                    <button @click="copyLink">
                        <img loading="lazy" decoding="async" src="../assets/Images/icon_copy.svg" width="25" height="25" alt="" />{{ copied ? $t("Copied") : $t("Copy Link") }}
                    </button>
                    <button @click="LinkedInShare">
                        <img loading="lazy" decoding="async" src="../assets/Images/bx_bxl-linkedin.svg" width="25" height="25" alt="" />{{ $t("Share on LinkedIn") }}
                    </button>
                </div>
            </div>
        </section>
        <section class="second-container"><span v-html="$t('RefferalViewBottomText')"> </span></section>
    </div>
</template>

<script>
import axios from "axios";
import LoaderComponent from "../components/LoaderComponent";
import ToastNotification from "../components/ToastNotification.vue";

export default {
    name: "RefferalView",
    components: { LoaderComponent, ToastNotification },
    data() {
        return {
            invitedEmail: "",
            shareLink: "go-platform.com",
            copied: false,
            url: "https://go-platform.com",
            title: "Recruitable",
            description: "Check out this great website!",
            subject: "Recruitable",
            body: "test",
            access_token: null,
            textPostContent: "Check out this great website!,Recruitable",
            user: { name: "", email: "" },
            selected: [true, false, false],
            current: 0,
            isLoading: false,
            isVisible: false,
            message: "",
            bgColor: "",
        };
    },
    methods: {
        selectTab(num) {
            this.selected[this.current] = false;
            this.selected[num] = true;
            this.current = num;
        },
        inviteEmail() {
            this.isLoading = true;
            axios
                .post(
                    "https://server.go-platform.com/invite/send-gmail",
                    {
                        email: this.invitedEmail,
                    },
                    { withCredentials: true },
                )
                .then(() => {
                    this.message = "Email sent successfully!";
                    this.bgc = "success";
                    this.isVisible = true;
                    this.invitedEmail = "";
                })
                .catch((error) => {
                    console.log(error);
                    this.message = "An error occurred while sending email.";
                    this.bgc = "error";
                    this.isVisible = true;
                })
                .finally(() => (this.isLoading = false));
        },
        copyLink() {
            navigator.clipboard
                .writeText(this.shareLink)
                .then(() => {
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 10000);
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        async LinkedInShare() {
            const url = "https://server.go-platform.com/linkedInShare/auth";
            window.open(url, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
* {
    font-family: "Roboto";
    font-style: normal;
}

.refferal {
    color: #343637;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.first-container {
    display: flex;
    gap: 1rem;
}

.second-container {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    span {
        background: rgb(143, 143, 143);
        color: #fff;
        border-radius: 10px;
        padding: 0 5px;
    }
}

.right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    //padding-top: 3rem;
    justify-content: center;
    height: 55%;
    gap: 3.5rem;

    span {
        font-size: 18px;
        line-height: 24px;
        display: flex;
    }

    .other_invitation {
        display: flex;
        flex-direction: column;

        justify-content: space-between;
        width: 80%;
        gap: 2rem;

        button {
            font-size: 16px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.25);
            border-radius: 10px;
            height: 3rem;
            background: #fff;
            transition: transform 0.3s ease-in-out;

            &:hover {
                transform: scale(1.08);
            }
        }
    }
}

.left-side {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 2rem;

    .img_gift {
        display: flex;
        margin-top: 0;
        flex-direction: column;
        background: rgba($color: #fff, $alpha: 0.5);
        align-items: center;
        border-radius: 20px;
        padding: 3rem 0;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(10px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        gap: 1rem;

        p {
            font-weight: 400;
            font-size: 28px;
            line-height: 42px;
            padding: 0 12%;
            text-align: center;

            > :nth-child(3) {
                color: #5d6e82;
                font-size: 18px;
                line-height: 24px;
                display: flex;
            }
        }
    }

    .invitation_btn {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        gap: 0.5rem;

        input {
            border: 1px solid #d8e2ee;
            box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
            padding: 0.8rem;
            border-radius: 10px;
            flex-grow: 1;
            font-size: 16px;
        }

        button {
            background: linear-gradient(135deg, #fab2ff 0%, #2196f3 100%);
            color: #fff;
            border-radius: 10px;
            padding: 0 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            border: none;
            //flex-grow: 0;
            font-size: 16px;

            &:hover {
                opacity: 0.85;
            }
        }
    }

    .colorfull {
        background: linear-gradient(180deg, #2196f3 0%, #ff3bf0 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        //text-fill-color: transparent;
    }
}

/*----------------------------------------------------------------*/
</style>
